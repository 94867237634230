import {version, Component} from 'inferno';

class Education extends Component {
    render() {
        return (
            <div className="container languages">
                <div className="columns">
                    <div className="column col-4 col-sm-12 col-md-12">
                        <div className="panel bg-white">
                            <div className="panel-header text-center">
                                <div className="spacer-small"></div>
                                <h4 className="panel-title subtitle">University of Leeds</h4>
                            </div>
                            <div className="panel-body text-center">
                                <p>Graduated with a First in Computer Science (BSc).</p>
                                <p>Was a Course Representative.</p>
                            </div>
                            <div className="panel-footer"></div>
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 col-md-12">
                        <div className="panel bg-white">
                            <div className="panel-header text-center">
                                <div className="spacer-small"></div>
                                <h4 className="panel-title subtitle">Allerton High School</h4>
                            </div>
                            <div className="panel-body text-center">
                                <p>A-Level grades were AABD in ICT, Maths, Chemistry and Biology respectively.</p>
                            </div>
                            <div className="panel-footer"></div>
                        </div>
                    </div>

                    <div className="column col-4 col-sm-12 col-md-12">
                        <div className="panel bg-white">
                            <div className="panel-header text-center">
                                <div className="spacer-small"></div>
                                <h4 className="panel-title subtitle">The Grammar School At Leeds</h4>
                            </div>
                            <div className="panel-body text-center">
                                <p>A few As and A*s, the majority Bs.</p>
                            </div>
                            <div className="panel-footer"></div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Education;
