import {version, Component} from 'inferno';

class Navbar extends Component {
    render() {
        return (
            <div className="navbar-container">
                <header className="navbar navbar-override">
                    <section className="navbar-section">
                        <a href="/" className="btn btn-link font1 blue">Gurpreet.</a>
                        <a href="#about" className="btn btn-link">about me</a>
                        <a href="#experience" className="btn btn-link">experience</a>
                        <a href="#projects" className="btn btn-link">projects</a>
                        <a href="#skills" className="btn btn-link">skills</a>
                        <a href="#contact" className="btn btn-link">contact</a>
                    </section>

                </header>
            </div>
        );
    }
}

export default Navbar;
