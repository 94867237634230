import {version, Component} from 'inferno';

class Contact extends Component {
    render() {
        return (
            <div className="container languages">
                <div className="columns">
                    <div className="column col-2 col-sm-12 col-md-12 hide-md hide-sm hide-xs" />

                    <div className="column col-4 col-sm-12 col-md-12">
                        <div className="panel bg-white">
                            <div className="panel-header text-center">
                                <div className="spacer-small"></div>
                                <h4 className="panel-title subtitle">@</h4>
                            </div>
                            <div className="panel-body">

                                <h6 className="subtitle">Email</h6>

                                <p>thecerrg@thecerrg.pb</p>
                                <p className="text-small text-secondary">
                                    Please don't send any email to the above address.
                                    It's an ROT13 encoded string to prevent a crawlers and spammers getting a hold of it.
                                    Please decode the above using a free ROT13 decoder online.
                                </p>

                                <div className="spacer"></div>

                                <h6 className="subtitle">GPG</h6>
                                <p>
                                    To confidentially send me a message, please use the following GPG keys. If it's a
                                    personal matter, please use my Personal GPG key. If it's a work-related matter,
                                    please use my Work GPG key.
                                </p>

                                <div className="spacer"></div>

                                <a className="btn btn-primary btn-sm" href="pub_keys/gurpreet_pub.asc" download="gurpreet_pub.asc">Personal Public Key</a>
                                <a className="btn btn-primary btn-sm" href="pub_keys/gurpreet_terl_pub.asc" download="gurpreet_terl.asc">Work Public Key</a>

                            </div>

                            <div className="panel-footer">
                            </div>
                        </div>
                    </div>
                    <div className="column col-4 col-sm-12 col-md-12">
                        <div className="panel bg-white">
                            <div className="panel-header text-center">
                                <div className="spacer-small"></div>
                                <h4 className="panel-title subtitle">Social</h4>
                            </div>
                            <div className="panel-body">

                                <h6 className="subtitle">LinkedIn</h6>
                                <a href="https://uk.linkedin.com/in/gurpreetpaul">https://uk.linkedin.com/in/gurpreetpaul</a>

                                <div className="spacer" />

                                <h6 className="subtitle">StackOverflow</h6>
                                <a href="https://stackoverflow.com/story/gurpreet-">https://stackoverflow.com/story/gurpreet-</a>

                                <div className="spacer" />

                                <h6 className="subtitle">HackerNews</h6>
                                <a href="https://news.ycombinator.com/user?id=gurpreet-">https://news.ycombinator.com/user?id=gurpreet-</a>

                                <div className="spacer" />

                                <h6 className="subtitle">Reddit</h6>
                                <a href="https://www.reddit.com/user/gurpreet-">https://www.reddit.com/user/gurpreet-</a>

                            </div>

                            <div className="panel-footer">
                            </div>
                        </div>
                    </div>

                    <div className="column col-2 col-sm-12 col-md-12 hide-md hide-sm hide-xs" />
                </div>
            </div>
        );
    }
}

export default Contact;
