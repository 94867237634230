import {version, Component} from 'inferno';

class Wall extends Component {
    render() {
        return (
            <div className="container languages">
                <div className="columns">
                    <div className="column col-4 col-sm-12" >
                        <h1 className="subtitle">Languages</h1>
                        <p>Java 8+</p>
                        <p>Kotlin</p>
                        <p>Android</p>
                        <p>Python</p>
                        <p>JavaScript</p>
                        <p>Dart</p>
                        <p>Swift</p>
                        <p>VBA</p>
                        <p>HTML</p>
                        <p>SCSS</p>
                    </div>
                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Languages II</h1>
                        <p>Ruby</p>
                        <p>C++</p>
                        <p>C#</p>
                        <p>Haskell</p>
                        <p>PHP</p>
                        <p>SourcePawn</p>
                        <p>C</p>
                        <p>SQL</p>
                    </div>
                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Languages III</h1>
                        <p>English</p>
                        <p>Punjabi</p>
                        <p>Hindi</p>
                        <p>German</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Methodologies</h1>
                        <p>Scrum</p>
                        <p>XP</p>
                        <p>Agile</p>
                        <p>Pair programming</p>
                        <p>Kanban</p>
                        <p>Test driven development</p>
                        <p>Feature driven development</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Frameworks</h1>
                        <p>VueJS</p>
                        <p>Vert.x</p>
                        <p>Play Framework</p>
                        <p>Ionic</p>
                        <p>Flutter</p>
                        <p>React / React Native</p>
                        <p>Django</p>
                        <p>Meteor</p>
                        <p>Angular 1</p>
                        <p>Ruby on Rails</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Mobile related</h1>
                        <p>Deployment to app stores</p>
                        <p>Gradle</p>
                        <p>Pods</p>
                        <p>Wire-framing</p>
                        <p>Android + iOS testing</p>
                        <p>Mobile databases</p>
                        <p>Networking</p>
                        <p>Retrofit</p>
                        <p>RxJava</p>
                        <p>Security</p>
                    </div>


                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Databases</h1>
                        <p>Postgres</p>
                        <p>MySQL</p>
                        <p>ArangoDB</p>
                        <p>Redis</p>
                        <p>MongoDB</p>
                        <p>SQLite</p>
                        <p>CockroachDB</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Database related</h1>
                        <p>SymmetricDS</p>
                        <p>Postgres logical replication</p>
                        <p>Liquibase migration tool</p>
                        <p>SQL Scripts</p>
                        <p>Restore / Backups</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Web related</h1>
                        <p>NodeJS</p>
                        <p>Bower</p>
                        <p>NPM</p>
                        <p>jQuery</p>
                        <p>WordPress</p>
                        <p>Nginx</p>
                        <p>REST</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Web related II</h1>
                        <p>SOAP</p>
                        <p>Reverse proxies</p>
                        <p>LetsEncrypt</p>
                        <p>cPanel</p>
                        <p>WebSockets</p>
                        <p>Adobe Dreamweaver</p>
                    </div>


                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">DevOps</h1>
                        <p>Ubuntu Server</p>
                        <p>Docker</p>
                        <p>Terraform</p>
                        <p>DC/OS</p>
                        <p>Kubernetes</p>
                        <p>Vagrant</p>
                        <p>SaltStack</p>
                        <p>Habitat.sh</p>
                        <p>Microservices</p>
                        <p>SemaphoreCI</p>
                        <p>GitLabCI</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">DevOps II</h1>
                        <p>Bash</p>
                        <p>Deployments</p>
                        <p>Server setup</p>
                        <p>CDNs</p>
                        <p>DigitalOcean</p>
                        <p>Netlify</p>
                        <p>Vultr</p>
                        <p>Domains</p>
                        <p>Firewalls</p>
                        <p>Dovecot</p>
                        <p>Postmark</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">IT</h1>
                        <p>On-site support</p>
                        <p>Domain changes</p>
                        <p>Image building</p>
                        <p>Reprographics</p>
                        <p>Wireless networks</p>
                        <p>Server building</p>
                        <p>LAN / WAN setup</p>
                        <p>Switches, routers</p>
                        <p>TeamViewer</p>
                    </div>



                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Security</h1>
                        <p>GPG</p>
                        <p>YubiKey</p>
                        <p>VPNs</p>
                        <p>SSL</p>
                        <p>SSH</p>
                        <p>Firewalls</p>
                        <p>CSP - Content Security Policy</p>
                    </div>


                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Security II</h1>
                        <p>XSS - Cross Site Scripting</p>
                        <p>HSTS - Strict Transport Security</p>
                        <p>HKPK</p>
                        <p>RSA</p>
                        <p>AES</p>
                        <p>Signing</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Code hygiene</h1>
                        <p>DRY</p>
                        <p>KISS</p>
                        <p>Indents</p>
                        <p>Clear, concise nomenclature</p>
                        <p>Abstractions</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Higher level code</h1>
                        <p>Composite-oriented programming</p>
                        <p>Streams</p>
                        <p>Distributed MPI</p>
                        <p>Parallelism</p>
                        <p>Threads</p>
                        <p>Reactive + Async programming</p>
                        <p>Functional programming</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Communications</h1>
                        <p>Slack</p>
                        <p>Gitter</p>
                        <p>Email</p>
                        <p>Teamwork.com</p>
                        <p>Wiki</p>
                    </div>


                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Digital</h1>
                        <p>SEO</p>
                        <p>Google Analytics</p>
                        <p>Google Tag Manager</p>
                        <p>Google Webmaster</p>
                        <p>Social media</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Operating systems</h1>
                        <p>MacOS</p>
                        <p>iOS</p>
                        <p>Android</p>
                        <p>Windows</p>
                        <p>Linux</p>
                        <p>Virtualisation (VirtualBox)</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Design / Photo / Video</h1>
                        <p>Affinity Photo</p>
                        <p>GIMP</p>
                        <p>Adobe Photoshop</p>
                        <p>Moqups</p>
                        <p>User scenarios</p>
                        <p>Sony Vegas</p>
                        <p>Source Film Maker</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Gaming related</h1>
                        <p>PyGame</p>
                        <p>CryEngine</p>
                        <p>PSP Modding</p>
                        <p>SourceServer</p>
                        <p>Source scripting</p>
                    </div>


                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Formats</h1>
                        <p>JSON</p>
                        <p>XML</p>
                        <p>CSV</p>
                        <p>YAML</p>
                        <p>CONF</p>
                    </div>


                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">VCS</h1>
                        <p>GitHub</p>
                        <p>GitLab</p>
                        <p>BitBucket</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Text Editors</h1>
                        <p>Vim</p>
                        <p>Sublime Text</p>
                        <p>Notepad++</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">IDEs</h1>
                        <p>JetBrains Suite</p>
                        <p>Eclipse</p>
                        <p>XCode</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Mail server</h1>
                        <p>Dovecot</p>
                        <p>SMTP</p>
                        <p>POP3</p>
                        <p>IMAP</p>
                    </div>

                    <div className="column col-4 col-sm-12">
                        <h1 className="subtitle">Enterprise</h1>
                        <p>JIRA</p>
                        <p>Confluence</p>
                        <p>GitHub Enterprise</p>
                        <p>Jenkins</p>
                        <p>SonarQube</p>
                    </div>


                </div>
            </div>
        );
    }
}

export default Wall;
