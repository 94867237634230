import {Component} from 'inferno';
import * as DB from "../databases/db";
import Person from "./Person";
import Project from "./Project";

class Job extends Component {

    projectChips(l) {
        let end = [];

        if (!l) {
            return end;
        }

        l.forEach(function(item, index, array) {
            let el = <Project project={item} />;
            end.push(el);
        });

        return end;
    }

    peopleChips(l) {
        let end = [];

        if (!l) {
            return end;
        }

        l.forEach(function(item, index, array) {
            let el = <Person small={true} person={item} />;
            end.push(el);
        });

        return end;
    }

    getButtons(btns) {
        let end = [];

        if (!btns) {
            return end;
        }

        end.push(<div className="spacer"></div>);
        btns.forEach(function(item, index, array) {
            let el = <a href={item.link} className="btn btn-primary">{ item.name }</a>;
            end.push(el);
        });

        return end;
    }

    getPeopleBlock() {
        if (this.props.people && this.props.people.length > 0) {
            return <div className="column col-6 col-sm-12 col-md-6">
                <p className="text-bold">People</p>
                {this.peopleChips(this.props.people)}
            </div>
        }
    }

    getProjectsBlock() {
        if (this.props.projects && this.props.projects.length > 0) {
            return <div className="column col-6 col-sm-12 col-md-6">
                <p className="text-bold">Related projects</p>
                {this.projectChips(this.props.projects)}
            </div>
        }
    }

    render() {
        let props = this.props;
        return (
            <div className="columns" style="padding: 2rem 0">
                <div className="column col-4 col-sm-12 col-md-12">
                    <div className="card experience-card">
                        <div className="card-image">
                            <img src={"experience/" + props.image} className="img-responsive"/>
                        </div>
                    </div>
                </div>
                <div className="column col-8 col-sm-12 col-md-12 spacer-left chip-space-small">
                    <div className="spacer"></div>
                    <h2 className="font0">{props.company}</h2>
                    <p><span className="text-bold">{props.position}</span> - <span className="text-gray-d1">{props.time}</span></p>
                    {props.description}


                    <div className="columns">
                        {this.getPeopleBlock()}
                        {this.getProjectsBlock()}
                    </div>

                    {this.getButtons(props.buttons)}
                </div>
            </div>
        );
    }
}

class Experience extends Component {

    constructor(props) {
        super(props);
    }

    convertToJob(item) {
        return <Job image={item.getImage()}
                    company={item.name}
                    position={item.position}
                    time={item.time}
                    people={item.people}
                    projects={item.projects}
                    description={item.description} />
    }

    render() {
        return (
            <div className="container experience">

                { this.convertToJob(DB.freeagent) }
                { this.convertToJob(DB.hsbc) }
                { this.convertToJob(DB.snapsales) }
                { this.convertToJob(DB.terl) }
                { this.convertToJob(DB.laal) }
                { this.convertToJob(DB.googleTech) }
                { this.convertToJob(DB.googleTechOnTour) }
                { this.convertToJob(DB.pbc) }
                { this.convertToJob(DB.ictTechnician) }
                { this.convertToJob(DB.reprographicsTechnician) }
                { this.convertToJob(DB.itEngineer) }
                { this.convertToJob(DB.boots) }
                { this.convertToJob(DB.mgftw) }

            </div>
        );
    }
}

export default Experience;
