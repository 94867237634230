import {version, Component} from 'inferno';
import {projects} from "../databases/db";


class ProjectOfWork extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        let end = [];
        for (let i = 0; i < projects.length; i++) {
            let project = projects[i];

            let btnEnd = [];
            if (project.buttons) {
                btnEnd = project.buttons.map(v => {
                    return <a className="btn btn-primary btn-sm" target="_blank" href={v.link}>{v.name}</a>
                });
            }

            let videosEnd = [];
            if (project.videos) {
                videosEnd = project.videos.map(v => {
                    return <video className="video-responsive">
                        <source src={v} type="video/mp4" />
                    </video>
                });
            }

            let el = <div className="column col-3 col-3 col-md-12">
                        {videosEnd}
                        <h4 className="subtitle">{ project.name }</h4>
                        <p>{ project.date }</p>
                        <p dangerouslySetInnerHTML={{__html: project.desc}}></p>
                        <div className="spacer-small"></div>
                        { btnEnd }
                    </div>;
            end.push(el)
        }
        return end;
    }

}

class Projects extends Component {
    render() {
        return (
            <div className="container languages">
                <div className="columns">

                    <ProjectOfWork />

                </div>
            </div>
        );
    }
}

export default Projects;
