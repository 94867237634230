
const PEOPLE_PATH = "people/";
const EXPERIENCE_PATH = "experience/";


export class Button {
    constructor(name, link) {
        this.name = name;
        this.link = link;
    }
}

export class Assignment {
    constructor(name, date, desc, buttons, videos) {
        this.name = name;
        this.date = date;
        this.desc = desc;
        this.buttons = buttons;
        this.videos = videos;
    }
}

export class Exp {

    constructor(name, imageName, position, time, description, people, projects, buttons) {
        this.name = name;
        this.imageName = imageName;
        this.position = position;
        this.time = time;
        this.description = description;
        this.people = people;
        this.projects = projects;
        this.buttons = buttons;
    }

    getImage() {
        return this.imageName;
    }

}


export class Human {
    constructor(name, imageName, about, thoughts, met, lessons) {
        this._name = name;
        this._imageName = imageName;
        this._about = about;
        this._thoughts = thoughts;
        this._met = met;
        this._lessons = lessons;
    }

    getImage() {
        if (this._imageName) {
            return this._imageName;
        } else {
            return PEOPLE_PATH + "profile.png";
        }
    }


    get name() {
        return this._name;
    }

    get imageName() {
        return this._imageName;
    }

    get about() {
        return this._about;
    }

    get thoughts() {
        return this._thoughts;
    }

    get met() {
        return this._met;
    }

    get lessons() {
        return this._lessons;
    }

    toDict() {
        let omage = this.getImage();
        let ab = JSON.stringify(this.about);
        return {
            name: this.name,
            imageName: omage,
            about: ab,
            thoughts: this.thoughts,
            met: this.met,
            lessons: this.lessons
        }
    }
}

export const SteveWainwright = new Human(
    "Steve Wainwright",
    PEOPLE_PATH + "steve_wainwright.jpg",
    <div>
        <p>
            Steve seems to know everyone. He is an overall brilliant person
            to talk to about anything as he is knowledgeable and world-wise.
        </p>
        <p>
            You can tell Steve has a wealth of management expertise.
            In the short time I've worked with Steve, I've noticed he listens to everyone. He involves everyone
            in meetings and he makes sure people's voices are heard. Being a true team player he wants
            as much input as possible to get the best outcome possible. He gives talented people
            the space and room to do their best.
        </p>
        <p>
            Steve actually brought me on board when I was at my lowest point mentally.
            His easy going nature allowed me to be both open and honest with him, which
            is a rare feat for me to do especially with someone of his caliber. I am in no
            doubt indebted to Steve for his help and advice.
        </p>
        <p>
            Steve has a easy going nature and wherever he goes he makes an impression.
            A true role model and undoubtedly one of the greats.
        </p>
    </div>,
    [
        "Genuine",
        "Happy",
        "Helpful",
        "Always positive",
        "Polite",
        "Super cool",
        "Humble",
        "100% experienced",
        "100% boss",
        "200% amazing",
    ],
    "Snapsales",
    [
        "Give talented people the room to do their best work.",
        "You become positive if you think positive.",
        "Give everyone a chance to speak and their thoughts heard.",
        "Listen to everyone."
    ]
);

export const BenWilson = new Human(
    "Ben Wilson",
    null,
    <div>
        <p>
            Ben is a very dedicated and determined person. He and I worked
            together building Dscvr. Even though I can be annoying sometimes,
            he answered every question I needed answering, often going above and
            beyond in helping me.
            He has a very positive aura and his focus on function over form
            gives him a clear vision of things to do.
        </p>
        <p>
            Ben is highly intelligent and self-aware. He contributes and encourages
            positive discussion. He makes a lasting impression in any situation.
            Ben has given me hope and motivated me through some tough times.
        </p>
        <p>
            Ben I think you have an amazing work ethic and are an overall great dude
            to be around. You have inspired me to make positive changes in my life.
        </p>
    </div>,
    [
        "Driven",
        "Knowledgeable",
        "Learns ultra fast",
        "Very well-spoken",
        "Down to earth",
        "Assisting and accommodating",
        "Will go far",
        "One to watch"
    ],
    "Snapsales",
    [
        "A healthy body is a healthy mind.",
        "Nothing is impossible.",
    ]
);

export const GoriYahaya = new Human(
    "Gori Yahaya",
    PEOPLE_PATH + "gori.jpg",
    <div>
        <p>
            During my time in the first ever Google Garage
            project, Gori taught me many things purely through action.
        </p>
        <p>
            Gori taught me that trusting your team members to perform is basically you
            saying, "I have confidence in your abilities, now go do great work".
            Gori taught me that no question is a stupid question (and I asked a lot of stupid questions).
            Although Gori was a very busy person,
            he always made sure the team
            was performing well and would always check up on each and every one of us.
        </p>
        <p>
            Gori had a world-wise air about him, I often thought he had the power
            to understand who you were just by looking at you.
        </p>
    </div>,
    [
        "Master of smoothness",
        "Helpful",
        "Manages without managing",
        "Treats everyone as equals",
        "Humble",
        "Intelligent",
        "Confident",
        "Always willing to listen to you"
    ],
    "Google Garage",
    [
        "Have confidence in your team.",
        "Admit when you don't know something.",
        "No question is stupid.",
        "Always be learning.",
        "Put the team first.",
    ]
);

export const BeckyBoyd = new Human(
    "Becky Boyd",
    PEOPLE_PATH + "becky_boyd.jpg",
    <div>
        <p>
            Becky is truly a favourite of mine. She has helped me so much
            throughout the years. She is the most friendly person
            I have ever met. Always positive and always up for a chat.
            She's a very good listener and makes the most of life.
        </p>
        <p>
            Becky has helped me find new opportunities, has always kept in touch,
            always pushed me professionally and listened to my growing list of problems.
            I am in no doubt indebted to Becky. Although she is primarily a social media guru,
            I have no hesitation in recommending
            her to anyone that needs advice on anything digital.
        </p>
        <p>
            She is absolutely brilliant, please make sure you follow her on all her social media.
        </p>
    </div>,
    [
        "Master of kindness",
        "Altruistic",
        "Helps you when you've fallen",
        "Treats everyone as if they're human",
        "Always willing to help you",
        "Soulful",
        "Cheerful",
        "Bright",
        "Resourceful"
    ],
    "Google Garage",
    [
        "Always be willing to help."
    ]
);

export const BeckyMathesonBruce = new Human(
    "Becky Matheson-Bruce",
    null,
    <div>
        <p>
            Becky is another favourite of mine. She worked alongside me
            when I was in the Google Garage. Her friendliness and enthusiasm
            always cheered me up. She is truly knowledgeable in a lot of areas
            in digital.
        </p>
        <p>
            Becky is a kind-natured, valiant, beautiful soul who looks out for you and
            always makes you laugh. She is what I like to call "a harmoniser". She makes
            teams function amazingly well because of her jovial attitude and her ability to unite
            teams.
        </p>
        <p>
            Becky always put the emphasis on people over processes and always
            thought about the impact to the team over any other detail. Becky treated
            me with respect and I look forward to working with Becky again!
        </p>
    </div>,
    [
        "Master of kindness",
        "Altruistic",
        "Helps you when you've fallen",
        "Treats everyone as if they're human",
        "Always willing to help you",
        "Soulful",
        "Cheerful",
        "Bright",
        "Resourceful"
    ],
    "Google Garage",
    [
        "People over processes.",
        "Harmonise, dear Watson.",
        "Smile."
    ]
);



export const JonathanFennell = new Human(
    "Jonathan Fennell",
    PEOPLE_PATH + "john.jpg",
    <div>
        <p>
            John is a megastar. He helped me out tonnes and brought me up to
            scratch in my software development skills. He always helped me
            out in every question I had. It was kind of embarrassing really, me shouting
            across the room to always ask for help, but John had the patience of a saint.
        </p>
        <p>
            John was foremost a professional and was a really cool, calm and collected person. I really enjoyed
            working with John. Once you get to know John, he is an impeccable conversationalist.
        </p>
        <p>
            I remember him allowing me to take a trip to Specsavers one morning because
            my eyes were hurting. His wife had suffered with something regarding her eyes so he made it
            a priority for me to go and get checked up. I am so glad I did go because I found out that I had very dry eyes
            and they were turning into cardboard from within the eyelids. Without John, I think
            I would never have gone to Specsavers and got eye drops and therefore would have permanently
            damaged my vision.
        </p>
        <p>
            John, set the standard for management early on. He made me feel comfortable
            and very welcome. Under him I worked at an insanely productive rate, I developed 2 apps
            all within 2 months.
        </p>
        <p>
            John, what is your secret? A management book with your name awaits.
        </p>
    </div>,
    [
        "Cool",
        "Calm",
        "Collected",
        "Understanding",
        "Empathetic",
        "Personable",
        "Guiding",
        "Patient",
        "Tolerant"
    ],
    "PinBellCom",
    [
        "Helping people early on gives you undying loyalty in the future.",
        "Have patience.",
        "Reward people."
    ]
);

export const EdwardBellamy = new Human(
    "Edward Bellamy",
    PEOPLE_PATH + "ed.jpg",
    <div>
        <p>
            Ed founded PinBellCom and he interacted with everyone with compassion
            and affection. Ed is a very friendly person, often sitting
            alongside his "underlings" and treating them as his equal.
            He took a sincere and honest approach to management. I often
            wondered how he managed to found a company, manage everyone and then sell it
            and stay a perfectly sane human being at the same time.
        </p>
        <p>
            Ed was always an open book and he treated me with utmost respect
            and concern. He had an intelligent sarcasm laced humour which I found really funny.
            He was always one to increase the moral in the room.
        </p>
        <p>
            Even after I left PinBellCom he kept in touch and often
            invited me back for a catch up and some tea. He would always keep in the loop
            about new opportunities. He is a gracious and kind person. He
            gave me opportunities that were amazing for my career development
            and I am eternally grateful.
        </p>
        <p>
            Ed was, for me, what a great entrepreneur looked like. Always
            projecting positivity, always listening, never criticising.
            I would like to work with Ed again as he and I share the same passion for
            healthtech.
        </p>
    </div>,
    [
        "Entrepreneur",
        "Open",
        "Honest",
        "Compassionate",
        "Very passionate",
        "Sarcastic humour",
        "Free spirit",
        "Wanted other people to do well",
        "Always put you in front",
        "Generous",
        "Courteous",
        "Gracious",
        "Kind"
    ],
    "PinBellCom",
    [
        "The connections you make on your journey through startup-land are worth more than anything.",
        "Be generous and kind.",
        "Always put your people in front of yourself.",
        "Spot passionate people early on.",
        "Train and inspire.",
        "Be friendly and courteous.",
    ]
);

export const AndrewPlummer = new Human(
    "Andrew Plummer",
    PEOPLE_PATH + "andrew.jpg",
    <div>
        <p>
            Andrew is a die-hard developer. I was amazed at his insane knowledge
            on Linux, specifically Arch Linux. As true developers, we would have
            geeky and exuberant debates over what text editor was best.
        </p>
        <p>
            Andrew is truly a brilliant person.
            He taught me <i>so</i> much. He is very intelligent
            and I often found myself stunned at his comprehensive knowledge.
            Not only that, his wit was incomparable and his sense of
            humour was wry and full-hearted.
        </p>
        <p>
            Seriously, if you're looking for a developer that
            can talk the talk and walk the walk Andrew is that person.
            He makes you feel welcome in the team. A true team harmoniser
            and all-round encyclopedia.
        </p>
        <p>
            Andrew, I have no problem working with you in the future
            if the chance arises and I
            wish you all the best in your future endeavours!
        </p>
    </div>,
    [
        "Rockstar developer",
        "Quick-witted",
        "Helpful",
        "All round expert",
        "Funny",
        "Friendly",
        "Awesome",
        "Caring",
        "Sympathetic",
        "Hard working",
        "Up for a laugh"
    ],
    "PinBellCom",
    [
        "No matter how good you think you are, there's always someone better.",
        "Learn lots from other people.",
        "Emacs is better than Vim (apparently).",
    ]
);

export const SteveWebber = new Human(
    "Steve Webber",
    PEOPLE_PATH + "steve_webber.jpg",
    <div>
        <p>
            Steve gave me my very first chance in the IT field.
            He knew how hard it was for people to get
            initial work experience in ICT and wanted to change that.
            I was Steve's very first work experience recruit and I wanted
            Steve to keep going. And he has. Since I last spoke to Steve, I
            found out he had 2 other recruits who had worked for him.
        </p>
        <p>
            Honestly, without you Steve, I'd never have had the motivation to
            go into a career of IT and therefore software engineering.
            Steve, I am eternally grateful for the opportunity you gave me
            and please do get in touch if you need anything.
        </p>
    </div>,
    ["Friendly", "Legendary", "Became the change he wanted to see", "Caring", "Open", "Honest"],
    "Westcom Networks",
    [
        "Giving people chances to prove themselves is worthwhile.",
        "Stick by something you believe in."
    ]
);

export const HollyStephens = new Human(
    "Holly Stephens",
    PEOPLE_PATH + "holly.jpg",
    <div>
        <p>
            Holly was in charge of the Google Garage on Tour project where I worked
            briefly with her in finessing the On Tour project and providing real-world
            feedback on it.
            She is easily one of the few people who I think are
            capable of taking on 1000 different things with ease.
        </p>
        <p>
            Holly is truly a role model for women in tech. Her marketing work is outstanding - often many
            high profile people from all over the country came into the Garage all
            referencing Holly. She would help us plan routes across Leeds and would
            coach us on what to say. She would organise events at the Garage raising
            its profile across Yorkshire.
            She took on every challenge and opportunity and worked as a team
            player. Holly built
            rapport very easily and was very down to earth and genuine. She
            answered every single question we needed answering and worked very hard to make
            the On Tour and the Garage project a success.
        </p>
        <p>
            Was one of my favourite Googlers and will go far in the future without a doubt.
        </p>
    </div>,
    [
        "Marketing whizz",
        "Role model",
        "Strong work ethic",
        "Genuine",
        "Honest",
        "Respectful",
        "Open minded",
        "Joyful",
        "Endearing",
        "Well connected",
        "Ambitious"
    ],
    "Digital Garage on Tour",
    [
        "Have a personality, don't be a robot.",
        "Connections, connections, connections.",
    ]
);


export const NoelleGhanem = new Human(
    "Noelle Ghanem",
    PEOPLE_PATH + "noelle.jpg",
    <div>
        <p>
            Noelle is a very sweet and professional person. Her willingness
            to get stuck into anything is admirable and contagious. She put
            her heart into everything whilst working alongside me on the On
            Tour project and she was truly an outstanding performer.
        </p>
        <p>
            She is a team player and works cooperatively with everyone.
        </p>
    </div>,
    [
        "Friendly",
        "Cooperative",
        "Gets along well with people",
        "Cooperative",
        "Honest",
    ],
    "Digital Garage on Tour",
    [
        "Cooperation is key.",
        "Don't be afraid to say something.",
    ]
);


export const PubuduDasanayaka = new Human(
    "Pubudu Dasanayaka",
    null,
    <div>
        <p>
            Pubudu was a great manager when I worked with him. He did lack
            trust initially in me as I was young, but as we got to know
            each other we really got on well.
        </p>
        <p>
            Pubudu is a man of many talents. His networking knowledge
            was first class and he imparted much knowledge with me. He greatly shared
            his insight into networks and DHCP and general IT work.
        </p>
    </div>,
    ["Networking guru", "Stern but amicable", "Determined"],
    "Allerton High School",
    [
        "Experience in more than one field is necessary to accomplish great things."
    ]
);

export const SamSaidi = new Human(
    "Sam Saidi",
    null,
    <div>
        <p>
            Sam was the first person to give me any sort of
            work experience at all. He was the manager of the chemist.
            I was a heavily gaming addicted waste
            of a human being, but Sam saw potential. I still have no idea
            what he saw 😂.
        </p>
        <p>
            While I was working with Sam I saw a theft within the chemist.
            The first theft was of baby powder milk and baby tissues by a mother pushing two
            prams. She hid the stolen goods in her prams and we had no idea they were
            taken until we saw the missing stock on the shelves and reviewed the CCTV.
        </p>
        <p>
            The chemist was in my hometown, which was not very economically
            well off. Sam told each of the staff members to not let the thieves in again.
            But honestly he felt sad doing so. He said that people around here were
            just trying to get by. The baby milk was probably for her children. He
            paid for the milk. He told me afterwards that he wished she would have just
            asked for the baby milk and he would have gave it.
        </p>
        <p>
            Although I
            knew theft occurred in my home town, I had no idea how blatant it was. Sam
            ingrained into me that people have problems we cannot see.
        </p>
    </div>,
    ["Early mentor", "Understanding", "Stressed", "Busy", "Gets the work done"],
    "Boots Chemist",
    [
        "Be kind, people are going through challenges we cannot see.",
        "Praise early, praise often.",
    ]
);

export const Marvel = new Human(
    "Marvel",
    null,
    <div>
        <p>
            Marvel was the first person who put me in a position
            of utmost responsibility. I was in charge of and had access to the forums,
            the store, the billing system, the databases, the servers, the email system,
            the Steam groups and had full administrator permissions.
        </p>
        <p>I was 13.</p>
        <p>
            There is very little I know about Marvel, who's real name is Martijn (I think). I know that
            he is Dutch and works as some sort of systems administrator in the Netherlands.
            He setup Marvelous Gaming as a hobby. He really enjoyed playing games and wanted to
            make it easier for other people to play games.
            The community he built was astounding. At its peak there was something like 5,000 players
            per month. It was such fun and I really felt like I belonged. It took me away from
            the stresses of real life.
        </p>
        <p>
            I'm not sure why Martijn gave people admin powers. I admired his courage to trust
            people with such powers over the Internet. I never got to find out how he selected
            his admins. But looking back, I think he saw passion and trialled his admins keeping
            a loose eye on them.
        </p>
    </div>,
    ["Laid back",
        "Lives for the passion of gaming",
        "Enjoys people's happiness",
        "Hands-off",
        "Well mannered and well-meaning",
        "Was never caught up in the details"
    ],
    "Marvelous Gaming",
    [
        "If a person shows enough passion, they can 90% of the time be trusted to handle a lot thrown at them.",
        "Build up a group of said trusted people and use them to help you make decisions.",
        "The Internet isn't a sad and lonely place if you know where to look for help.",
    ]
);


export const dscvr = new Assignment(
    "Dscvr",
    "2018",
    "Dscvr is a discount app that allows shops to selectively " +
    "get customers in at certain times. It is a brilliant take on discounts " +
    "that no one has had the gumption to fully do.",
    [
        new Button("View", "https://play.google.com/store/apps/details?id=com.snapsales.dscvr"),
        new Button("Video", "https://gurpreet-.ams3.digitaloceanspaces.com/videos/dscvr.mp4"),
    ],
);

export const fupp = new Assignment(
    "FUPP",
    "2018",
    "I developed a front-end for a recruitment company " +
    "that had an interesting take on recruitment. Designed by an external designer.",
    [
        new Button("Video", "https://gurpreet-.ams3.digitaloceanspaces.com/videos/fupp.mp4"),
    ],
);

export const lazysodium = new Assignment(
    "Lazysodium",
    "2018",
    "Lazysodium is a Libsodium Android / Java binding, which makes it effortless to " +
    "add great cryptography to your applications. Built primarily for Recordo. " +
    "This library is used in many projects. I'm proud to say this library is used by <span class='text-bold'>millions</span> worldwide via " +
    "<a href='https://threema.ch/en'>Threema</a> and a bunch of other <a href='https://docs.lazycode.co/lazysodium/projects'>notable projects</a>.",
    [
        new Button("View", "https://github.com/terl/lazysodium-java"),
        new Button("Play Store", "https://play.google.com/store/apps/details?id=com.goterl.lazycode.lazysodium.example"),
    ]
);

export const recordo = new Assignment(
    "Recordo",
    "2017",
    "Recordo is a cryptographically secure, open-source diary / journal app built on pure Android. " +
    "It's a project from my company, Terl. I have a waiting list of around <span class='text-bold'>60 users</span>.",
    [
        new Button("View", "https://recordo.co"),
        new Button("GitHub", "https://github.com/terl/recordo-android")
    ]
);

export const systraApp = new Assignment(
    "Systra Survey App",
    "2016",
    "I developed and designed a native Android " +
    "survey app which was released around the country to many " +
    "train stations and handed to hundreds of people to" +
    "fill in live. This was a complicated project that required SQLite and proper schema planning.",
    [new Button("Video", "https://gurpreet-.ams3.digitaloceanspaces.com/videos/survey.mp4")]
);

export const googleGlass = new Assignment(
    "Google Glass For Training and Education",
    "2016",
    "My final year project. I got this to a reasonable state. I coded a live streaming Glass App " +
    "which live streamed a video feed to a server and back again. I used raw" +
    "threads but the limitations of Glass proved to be too much to handle.",
    [new Button("Download report", "projects/google_glass.pdf")]
);

export const crimeEye = new Assignment(
    "Crime Eye",
    "2015",
    "This ambitious iOS app was coded within " +
    "3 weeks by me and two other team members. " +
    "The end result? An app that shows you exactly where " +
    "crimes have happened on a map given a postcode you supply.",
    [new Button("View", "https://github.com/crime-eye")]
);


export const tgiu = new Assignment(
    "The Gig Is Up",
    "2015",
    "First large software group project. " +
    "Aim was simple: Create a gig booking system. " +
    "I took the lead on this one, organising scrum meetings " +
    "with the team and working on the website, app and server.",
    [
        new Button("View", "https://github.com/the-gig-is-up"),
        new Button("Download", "projects/tgiu.apk"),
        new Button("Play Store", "https://play.google.com/store/apps/details?id=com.dolphin.thegigisup"),
    ]
);


export const smtwIos = new Assignment(
    "Show Me The Weather (iOS)",
    "2015",
    "This is the iOS version of the Show Me The Weather app. Part of another " +
    "university project.",
    [new Button("View", "https://github.com/gurpreet-/show-me-the-weather-ios")]
);

export const smtwAndroid = new Assignment(
    "Show Me The Weather (Android)",
    "2014",
    "An app I created for a University project. It shows the weather. Created for Android apps.",
    [
        new Button("View", "https://github.com/gurpreet-/show-me-the-weather"),
        new Button("Download", "projects/smtw.apk")
    ]
);

export const mandsIncidents = new Assignment(
    "M&S Incidents",
    "2014",
    "After my previous success with Ionic I was brought in to do it again but this time for M&S. " +
    "I designed and completed an incidents app for M&S which they were very impressed with.",
    []
);

export const intradocChecklists = new Assignment(
    "Intradoc 24/7 Checklists",
    "2014",
    "I developed this as a hybrid app using Ionic. " +
    "I had an immense amount of fun learning Ionic which later " +
    "led me to doing the M&S app.",
    []
);

export const princeTutti = new Assignment(
    "Prince Tutankhamen",
    "2013",
    "This was my first game and first ever dual team university project. " +
    "There was a game on Sky Games called Prince Tutti. " +
    "You got chased by several mummies in mazes. You collected keys " +
    "to progress to the next level. It was fun. So I decided to remake it. " +
    "I did it in PyGame.",
    [new Button("View", "https://github.com/gurpreet-/prince-tutti")],
);

export const mathematrics = new Assignment(
    "Mathematrics",
    "2013",
    "Mathematrics is one of my first ever websites. " +
    "It was quite badly designed but worked. " +
    "It's aim was to teach people tricks in Maths. " +
    "I used Dreamweaver for bits of this and the rest I hand coded.",
    [new Button("View", "https://gitlab.com/gurpreet-/mathematrics")],
);

export const raptshia = new Assignment(
    "Raptshia Games",
    "2012",
    "As part of a project for high school, a fictional company wanted a database. " +
    "I did some programming in VBA to achieve this goal.",
    [new Button("View", "https://gitlab.com/gurpreet-/raptshia-games")],
);

export const l4dDoorLock = new Assignment(
    "L4D Door Lock",
    "2010",
    "As a young teenager, throughout high school, I coded some plugins " +
    "for the Marvelous Gaming community and for L4D2. All done in SourcePawn.",
    [new Button("Download", "projects/l4d_lock.zip")],
);

export const freeagent = new Exp(
    "FreeAgent",
    "freeagent.jpg",
    "Staff Software Engineer",
    "Jan 2022 to Present",
    <div>
        <p>
            Working 100% remotely as part of a high-performance mobile team to deliver the FreeAgent app that is backed by NatWest, is delivered to over 100,000 customers and is currently rated 4.5⭑! The app uses the latest and greatest such as MVVM, Kotlin + Coroutines, Viewbinding, Jetpack Navigation and its own caching layer.
            As a staff software engineer on the mobile team I am responsible for:
        </p>
        <ul>
            <li>
                Resolving customer-facing issues, e.g. any breaking changes that affect the customer. Through Firebase Crashlytics we can see daily reports of any crashes that we may not have seen during the development and testing phase.
            </li>
            <li>
                Delivering the app end-to-end including uploading to the Play Store, e.g. I contribute to any releases we have.
            </li>
            <li>
                Making key high-level decisions including any architectural changes e.g. I was responsible for moving the app from an MVP architecture to an MVVM architecture. I was a key player in making sure our test suite was running and reporting accurate coverage, reducing tech debt by half in the first 3 months of joining.
            </li>
            <li>
                Bringing awareness to other teams issues that may impact the mobile team, e.g. the API team may make some change that will break the Android and iOS apps.
            </li>
            <li>
                Working in tandem with the iOS and others teams to ensure the app is kept in-sync with them all, e.g. for any new features, I must work with our product owners and the communications team to bring a new feature to market.
            </li>
            <li>
                Helping new developers with any problems they have with the app, e.g any new developer thats onboarded, I support them. I have wrote our own documentation pages to help learning about the codebase easier.
            </li>
            <li>
                Helped in interviewing candidates.
            </li>
        </ul>
    </div>
);


export const hsbc = new Exp(
    "HSBC",
    "hsbc.jpg",
    "Mobile Developer",
    "April 2019 to December 2021",
    <div>
        <p>
            As a Mobile Developer at HSBC, I am in charge of all 26+
            apps built by HSBC and its group members (which includes HSBC UK, HSBC UAE, First Direct and
            M&S Bank and loads of other apps).
        </p>
        <ul>
            <li>
                Within a year I quickly rose up the ranks and became code maintainer of all of the apps
                and several very critical internal libraries.
            </li>
            <li>
                Refactored all of the copy inside one module. Became the defacto go-to developer
                for internationalisation and Gradle module topics.
            </li>
            <li>
                Created documentation that improved productivity for the consumption of HSBC's
                global developer community.
            </li>
            <li>
                Recognised by management for outstanding contributions helping the whole of
                the global HSBC developer community.
            </li>
            <li>
                Led the charge against the most difficult problem in Android - we hit
                the 64K strings limit.
            </li>
        </ul>
    </div>
);


export const snapsales = new Exp(
    "Snapsales",
    "snapsales.png",
    "Android Lead",
    "September 2018 to April 2019",
    <div>
        <p>
            At Snapsales I built an app called <a href="https://play.google.com/store/apps/details?id=com.snapsales.dscvr">Dscvr</a>,
            which is a hyper-local real-time discounts app, freshly released to the app store. I was
            in charge of building the whole native Android app from scratch to bring it into
            feature parity with the iOS app. I had to wear many hats but primarily operated on the front end Android app.
            3 months in, I advocated the use of Flutter. This allowed Snapsales to progress faster as
            Flutter works on both iOS and Android so there was less development time needed.
        </p>
        <ul>
            <li>Worked closely with Android technologies.</li>
            <li>More practice being part of a small but growing team.</li>
            <li>Advocated the use of various technologies so that we can get to market fast.</li>
            <li>Worked to business requirements.</li>
            <li>Worked with bluetooth technologies.</li>
            <li>Launched the app on the Play Store.</li>
        </ul>

    </div>,
    [SteveWainwright, BenWilson],
    [dscvr]
);


export const terl = new Exp(
    "Terl",
    "terl.png",
    "Founder and Director",
    "May 2016 to Present",
    <div>
        <p>
            Founded a company specialising in solving social issues such as mental health and wellbeing.
            Terl stands for tech relief. The main developing <a href="https://recordo.co">Recordo</a>, a mental health app.
        </p>
        <p>
            Investing time into Recordo helped me understand that what users really wanted wasn't
            necessarily what I thought they wanted. For example, offline-sync is something I worked on for 6 months. But
            it was an added benefit that no one really cared about! Amongst things like
            technical architecting, schemas, devops, databases and user testing I learnt loads of new tech that
            made me a better generalist.
        </p>
        <ul>
            <li><a href="https://github.com/terl/lazysodium-java">Lazysodium</a>, the cryptography library
                that powers Recordo, grew to be very popular with Blockchain projects and security based projects.</li>
        </ul>
    </div>,
    [],
    [lazysodium, recordo],
    [new Button("Visit website", "https://terl.co")]
);


export const laal = new Exp(
    "Gurpreet.co",
    "g.png",
    "Freelancer",
    "Jan 2011-ish to Present",
    <div>
        <p>
            I've been doing some contract work on various projects. Sometimes advising
            charities on their digital strategy.
        </p>
        <ul>
            <li>Consulting and meeting clients on a one-to-one basis.</li>
            <li>Assisting and helping charities and small businesses decipher complex development contracts.</li>
            <li>Using remote tools like Slack and Google Meet to work.</li>
            <li>Creating high quality software.</li>
            <li>Giving up-to-date advice on modern tools to use.</li>
            <li>Refactoring and language advice.</li>
        </ul>
    </div>,
    [],
    [systraApp, fupp, l4dDoorLock],
    [new Button("Visit website", "https://laal.works")]
);


export const googleTech = new Exp(
    "Google",
    "google.gif",
    "Google Technician",
    "March 2015 to October 2015",
    <div>
        <p>
            Google were in Leeds to help SMEs with their
            digital presence. I was part of the first ever team of Garagers.
            A whole range of topics were discussed in each consultation session
            that lasted approximately 60 minutes. Everything from Google Analytics, Google Adwords,
            Google My Business and Social Media. Business owners would ask strategy and heavily contextual
            questions based on what their situation was.
        </p>
        <ul>
            <li>Representing Google being a public face for the global company.</li>
            <li>Dealing with the press and local news outlets.</li>
            <li>Talking to business owners at a high level.</li>
            <li>Tailored advice for each business on their website and social media.</li>
            <li>Resolving technical problems with Google products such as Analytics and AdWords.</li>
            <li>Mentoring people on Google My Business.</li>
            <li>In-depth understanding of Google PageRank.</li>
        </ul>
    </div>,
    [GoriYahaya, BeckyBoyd, BeckyMathesonBruce],
    [],
    []
);


export const googleTechOnTour = new Exp(
    "Google",
    "google.gif",
    "Google on Tour Technician",
    "March 2015 to October 2015",
    <div>
        <p>
            I led the first ever Digital Garage on Tour. It was much like
            the above role but rather than businesses coming to the garage,
            we would meet businesses where they were situated.
        </p>
        <ul>
            <li>Representing Google professionally on-foot.</li>
            <li>Helping to organise, plan and stress-test the first ever On Tour programme.</li>
            <li>Led a team of 2 talking to small to medium businesses.</li>
            <li>Friendly and affable attitude was required.</li>
            <li>Positive and fast-thinking was required to talk to business owners who were often very busy.</li>
            <li>Gave critical feedback to Google who would use the data to feed into the next DGoT programme.</li>
        </ul>
    </div>,
    [HollyStephens, NoelleGhanem],
    [],
    []
);

export const pbc = new Exp(
    "PinBellCom",
    "pbc.gif",
    "Jr Software Developer",
    "July 2014 to March 2015",
    <div>
        <p>
            Worked as a part of a team that developed several mobile apps for the NHS
            (specifically GP surgeries) and Marks and Spencer. I was
            quickly given ownership of the two projects which were developed within 2 months altogether.
            The apps were deployed on the iOS App Store and the Google Play Store.
        </p>
        <p>
            The M&S app called "Incidents" is still, to my knowledge, being used
            nationwide. The NHS app called "Intradoc 24/7" is now formalised as part of
            the Intradoc 24/7 suite of software. Although my contract officially ended in March 2015,
            I worked for PinBellCom during my university summer holidays and then was put on a zero hour contract
            in the hope that I would return to employment with PinBellCom. However, I did not return as university kept
            me busy.
        </p>
        <ul>
            <li>Developed and worked on several APIs which allowed communication between the AJAX apps and SQL servers.</li>
            <li>PHP, HTML and CSS were used for the backend and Ionic with AngularJS was used for the frontend.</li>
            <li>Learnt and used HTTP GET, POST and auth tokens.</li>
            <li>Undertook demos and presentations to M&S.</li>
        </ul>
    </div>,
    [EdwardBellamy, JonathanFennell, AndrewPlummer],
    [mandsIncidents, intradocChecklists],
    []
);


export const ictTechnician = new Exp(
    "Allerton High School",
    "ahs.gif",
    "ICT Technician",
    "July 2013 to Oct 2013",
    <div>
        <p>
            Mounted new desktop computers and removed old computers manually.
            Created images of Windows 7 Professional.
            The Windows 7 images had to be compatible with desktop and laptop machines. Then they were installed onto
            hundreds of computers manually.
            Fixed a range of computers around the school as some of them were in disrepair.
        </p>
        <ul>
            <li>Working as part of a team to un-mount and mount hundreds of computers from their bases several times over.</li>
            <li>Created a base image that was to be installed everywhere.</li>
            <li>Helped do all the manual work of carrying trolley loads of laptops and making sure they all worked.</li>
            <li>Massive patience was involved in testing every laptop and computer.</li>
            <li>Listened to direct manager and worked to maintain a good IT presence for teachers and admin staff.</li>
        </ul>
    </div>,
    [PubuduDasanayaka],
    [],
    []
);

export const reprographicsTechnician = new Exp(
    "Allerton High School",
    "ahs.gif",
    "Reprographics Technician",
    "July 2013 to Oct 2013",
    <div>
        <p>
            Did this alongside the above job.
            Organised and printed the school's work for teachers and administrators.
            Maintained and checked printers using networking software. Helped resolve
            common printing issues around the school. Did a lot of booklet, binding, laminating and holepunching work.
        </p>
        <ul>
            <li>Copied and printed the whole school's documents.</li>
            <li>Use of organisational skills to coordinate this undertaking.</li>
            <li>Timing was essential.</li>
            <li>Communicating as to how long a print would take was crucial.</li>
            <li>A good attitude to the job was required because of the large amount of teachers and students that
                were being served.</li>
        </ul>
    </div>,
    [],
    [],
    []
);


export const itEngineer = new Exp(
    "Westcom Networks",
    "westcom.png",
    "IT Engineer",
    "June 2012 to July 2012",
    <div>
        <p>
            A hands on job role in which I travelled to and worked in many places such as London and York. This role required me to:
        </p>
        <ul>
            <li>Build servers from scratch; this included the physical building of the servers.</li>
            <li>It was expected that I had reasonable knowledge of the workings of computers.</li>
            <li>Install VMWare onto the servers.</li>
            <li>Check the integrity of wireless networks around schools using a diagnostic app.</li>
        </ul>
    </div>,
    [SteveWebber],
    [],
    []
);

export const boots = new Exp(
    "Boots Pharmacy",
    "boots.png",
    "Assistant Chemist",
    "Oct 2011 to Oct 2011",
    <div>
        <p>
            As part of my high school work experience, I went to work in a chemist near my
            home town.
        </p>
        <ul>
            <li>Stocked shelves and monitored stock inside the chemist.</li>
            <li>Helped pharmacists with small tasks.</li>
            <li>Managed the till from time-to-time.</li>
            <li>Used the POS.</li>
        </ul>
    </div>,
    [SamSaidi],
    [],
    []
);


export const mgftw = new Exp(
    "Marvelous Gaming",
    "marvelous.png",
    "Head Administrator",
    "January 2010 to April 2013",
    <div>
        <p>
            I am ashamed to admit I was a heavy gamer throughout high school. I did not fit in
            at school and so online gaming was my only mental break. Because I was playing
            so much and so often, I was given the prestigious opportunity to become a Trial Administrator
            in an online community called Marvelous Gaming.
            I then became a Moderator, which then turned into Head Administrator. Head Administrator
            meant that I had full control over the game servers, the systems, the forums and everything else.
        </p>
        <p>
            With great power comes great responsibility. And boy was there responsibility.
            There were more than a thousand monthly players playing L4D2 on MG's servers
            which I had to monitor and manage - most of them
            older than me. I was to be level headed when handing out bans for griefing and racism. I
            was to be good at the game. I was to communicate effectively remotely with other admins.
            I also learnt how to code scripting plugins for the game.
            My parents had no hope for me in future as a result of my gaming. But it wasn't a negative for me,
            it was a positive. I learnt:
        </p>
        <ul>
            <li>People skills.</li>
            <li>How to communicate effectively remotely.</li>
            <li>Being mature.</li>
            <li>First steps in coding and scripting.</li>
            <li>Managing vBulletin and forum software.</li>
            <li>Installing SourceMod on servers.</li>
            <li>Creating server instances.</li>
            <li>Using SSH and linux commands.</li>
        </ul>
    </div>,
    [Marvel],
    [l4dDoorLock],
    []
);


export const projects = [
    dscvr,
    fupp,
    lazysodium,
    recordo,
    systraApp,
    googleGlass,
    crimeEye,
    smtwIos,
    smtwAndroid,
    mandsIncidents,
    intradocChecklists,
    princeTutti,
    mathematrics,
    raptshia,
    l4dDoorLock
];

window.currentPerson = SteveWainwright.toDict();