import {Component, createRef} from 'inferno';
import {createChippy} from "../App";

class PersonModal extends Component {

    constructor(props) {
        super(props);
        window.modalRef = createRef();
    }

    componentDidMount() {
        const t = this;
        window.addEventListener('refreshRender', function(e) {
            t.showModal();
            // Force an update as we've got a
            // new current person
            t.forceUpdate();
        }, false);
    }

    hideModal() {
        const modal = window.modalRef.current;
        modal.classList.remove("active");
    }

    showModal() {
        // const modal = window.modalRef.current;
        // modal.classList.add("active");
    }

    createLessons(lessons) {
        let end = [];

        if (!lessons) {
            return end;
        }

        lessons.forEach(function(item, index, array) {
            let el = <li>{ item }</li>;
            end.push(el);
        });

        return end;
    }


    render() {
        let person = window.currentPerson;
        let about = JSON.parse(person.about);

        return (
            <div className="modal modal-lg" ref={window.modalRef}>
                <a onClick={this.hideModal} className="modal-overlay" aria-label="Close"></a>
                <div className="modal-container" style="position: relative">
                    <div className="modal-body" style="padding: 4rem 1rem !important">

                        <div className="text-center">
                            <figure className="avatar avatar-xxl" data-initial="YZ" style="background-color: powderblue;">
                                <img src={ person.imageName } />
                            </figure>
                            <div className="spacer"></div>
                            <h3 className="font0">{ person.name }</h3>
                        </div>

                        <div className="spacer-small"></div>

                        <div className="columns padding">
                            <div className="column col-7 col-sm-12 col-md-12" style="padding: 0 20px">
                                <p className="text-bold text-large text-gray">About</p>
                                { about }
                                <div className="spacer"></div>

                                <p className="text-bold text-large text-gray">Lessons learnt</p>
                                <ul>
                                    { this.createLessons(person.lessons) }
                                </ul>

                                <div className="spacer"></div>

                            </div>
                            <div className="column col-5 col-sm-12 col-md-12" style="padding: 0 20px">
                                <p className="text-bold text-large text-gray">Met</p>
                                <p>{ person.met }</p>

                                <div className="spacer"></div>

                                <p className="text-bold text-large text-gray">Thoughts</p>
                                {createChippy(person.thoughts)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}

export default PersonModal;
