import {Component} from 'inferno';


class Project extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let project = this.props.project;
        return (
            <a href="#projects"><span className="label">{ project.name }</span></a>
        )
    }

}

export default Project;