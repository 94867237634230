import {Component} from 'inferno';


class Person extends Component {

    constructor(props) {
        super(props);
    }

    onChipClick(person) {
        // Send an event that we've refreshed the current person
        window.currentPerson = person;
        let event = new Event('refreshRender');
        window.dispatchEvent(event);
    }

    render() {
        const avatarSize = (this.props.small === true) ? "avatar-lg" : "avatar-xl";
        let person = this.props.person.toDict();
        return (
            <div className="chip" onClick={ e => this.onChipClick(person)}>
                <img src={person.imageName} className={"avatar " + avatarSize} />
                { person.name }
            </div>
        )
    }

}

export default Person;