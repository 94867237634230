import {createRef, Component} from 'inferno';
import { BrowserRouter, Switch, Route, Link, withRouter } from 'inferno-router';
import './registerServiceWorker';
import GAnalytics from 'ganalytics';
import Navbar from "./components/Navbar";
import Wall from "./components/Wall";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import PersonModal from "./components/PersonModal";
import Education from "./components/Education";



export function createChippy(tags) {
    if (!tags) {
        return [];
    }
    const elements = [];
    for (let i = 0; i < tags.length; i++) {
        elements.push(<span className="chippy">{ tags[i] }</span>);
    }
    return elements;
}


class BaseComponent extends Component {

    constructor(props) {
        super(props)
    }

    componentWillReceiveProps(nextProps) {
        // will be true
        const locationChanged = nextProps.location !== this.props.location;
        if (locationChanged) {
            nextProps.visitor.send('pageview', {dp: nextProps.location.pathname + nextProps.location.search});
        }
    }

}


class App extends Component {

    constructor(props) {
        super(props);
        const visitor = new GAnalytics('UA-44918250-2');
        this.state = {visitor};
        this.scrollContainer = createRef();
        this.scrollDiv = createRef();
        window.modalRef = createRef();
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        //document.getElementById('experience').scrollIntoView();
        //this.scrollDiv.scrollIntoView();
    }

    render() {
        return (
            <BrowserRouter>
                <div>
                    <Route render={(props) => <BaseComponent {...props} visitor={this.state.visitor} />} />
                    <Route exact path="/" component={Home}/>
                </div>
            </BrowserRouter>
        );
    }
}



class Home extends Component {

    render() {
        return (
            <div className="full-app">
                <div className="left-pillar hide-md">
                    <div className="left-side-bar-img">
                        <img src="gurpreet.png" className="profile-pic" />
                    </div>
                    <div className="left-side-bar-center">
                        <p className="font1 text-bt center-align">Gurpreet. <span className="font0"> - Design-conscious engineer and creator.</span> </p>
                    </div>
                </div>

                <div className="main-container" ref={this.scrollContainer}>
                    <Navbar />
                    <div className="hero-heading-container">
                        <div className="hero-heading">
                            <img src="gurpreet.png" width="60" className="blue show-md" alt="My profile picture" />
                            <div className="spacer show-md"></div>
                            <p className="font1 hero">
                                I'm a software engineer, creator and design enthusiast.
                            </p>
                            <p style="font-size: 1.8rem;">I make beautiful things.</p>
                        </div>
                    </div>
                    <span className="anchor" id="about"></span>
                    <div className="part" style="margin-top: -1rem; background-color: #e7e9ed;">
                        <div className="section-60">
                            <h1>About me.</h1>
                            <p>
                                I grew up with technology. From gaming to scripter to engineer, I've
                                delved into a huge amount of fields related to technology.
                            </p>

                            <p>
                                I was raised in a small town called Harehills in Leeds, UK 🇬🇧. My first foray into
                                technology was the task of setting up the Internet. My parents,
                                both immigrants, had no money to spare for an engineer to come and set it up for us.
                                Words like "Subnet mask" and "DHCP" simultaneously
                                baffled and intrigued me. I started learning and learning.
                                3 weeks later, I got it to work.
                            </p>

                            <p>
                                My thirst for knowledge was only amplified once I got on the Internet. I wanted to know
                                how the Internet worked, I wanted to read the whole of Wikipedia, I wanted to know
                                how to build a computer. Even seemingly benign topics like "who controls the Internet?"
                                drew me into a world of bits and bytes. A world which I haven't still gotten out of.
                            </p>
                            <p>
                                Being a heavily addicted gamer back then, I joined an online gaming community, self-taught myself
                                how to code, excelled in IT classes and gained a lot of experience in various jobs. After graduating
                                from the University of Leeds, I thought back to my life growing up
                                and wanted to find ways of helping the types of people who were in my position when I was younger.
                                So I setup <a href="https://terl.co">Terl</a>, a business aimed at providing relief through technology.
                            </p>

                            <p>Right now, I'm working on a couple of major things. Read on below.</p>

                        </div>
                    </div>

                    <span className="anchor" id="experience" ref={this.scrollDiv}></span>
                    <div className="part">
                        <div className="text-center">
                            <h1>My experience.</h1>
                            <p>This is my online CV/resume. The following jobs are all full-time permanent positions unless otherwise stated.</p>
                        </div>

                        <div className="spacer"></div>
                        <div className="spacer"></div>

                        <Experience />

                    </div>


                    <span className="anchor" id="projects"></span>
                    <div className="part background-color-light-gray2">
                        <div>
                            <h1>Projects.</h1>
                            <p>Browse a list of my projects.</p>

                            <div className="spacer"></div>
                            <div className="spacer"></div>

                            <Projects/>

                        </div>
                    </div>


                    <span className="anchor" id="education" ref={this.scrollDiv}></span>
                    <div className="part" style="padding-top: 6rem; padding-bottom: 6rem;">
                        <div>
                            <h1>My education.</h1>
                            <p>Where I've studied.</p>
                        </div>

                        <div className="spacer"></div>
                        <div className="spacer"></div>

                        <Education />

                    </div>

                    <span className="anchor" id="skills"></span>
                    <div className="part background-color-light-gray2">
                        <h1>My skills.</h1>
                        <p>A section devoted to the skills, tools and technologies I know.</p>

                        <div className="spacer"></div>
                        <div className="spacer"></div>
                        <div className="spacer"></div>

                        <Wall />
                    </div>


                    <span className="anchor" id="contact"></span>
                    <div className="part background-color-light-blue2">
                        <div>
                            <h1>Contact.</h1>
                            <p>Learn the best way to get in touch with me.</p>

                            <div className="spacer"></div>
                            <div className="spacer"></div>

                            <Contact/>
                        </div>
                    </div>


                    <PersonModal />


                </div>
            </div>
        )

    }


}


export default App;
